@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';


.hero-container {
    @include font-styling;
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    z-index: 3;

    .hero-video {

        // width:100%;
        video {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .hero-front-text {
        position: relative;
        // width: 100%;
        margin-top: 5vh;

        .hero-header {
            font-size: 30px;
            // margin: 0 10px;
            text-align: center;
        }

        .hero-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 15px;
            // padding: 15px;
            margin-top: 15px;

            .play-button {
                display: flex;
                align-items: center;
                justify-items: center;
                padding: 15px;
                background-color: #000000;
                border: none;
                border-radius: 50%;

                img {
                    width: 10px;
                }
            }

            p {
                font-size: 16px;
                font-weight: 800;
            }
        }

        .hero-button :hover {
            cursor: pointer;
            color: #dfdddd;
        }

        .hero-text-stack {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            row-gap: 30px;
            column-gap: 0px;
            margin-top: 20px;

            .text-wrapper {
                display: flex;
                flex-direction: column;
                gap: 5px;
                align-items: center;

                .text-title {
                    p {
                        font-size: 16px;
                        font-weight: 800;
                    }
                }

                .text-desc {
                    p {
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .hero-container {
        height: 500px;
        width: unset;

        .hero-front-text {
            margin: 99px 250px 99px 250px;

            .hero-header {
                // margin: 0 90px 0 0px;
                font-size: 50px;
                text-align: start;
            }

            .hero-button {
                justify-content: flex-start;
                // margin-left: 6vw;

                .play-button {
                    padding: 30px;

                    img {
                        width: 20px;
                    }
                }

                p {
                    font-size: 24px;
                }
            }
        }

        .hero-text-stack {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            justify-items: flex-start;
            align-items: flex-start;
            width: 30%;
            align-items: flex-start;


            .text-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

}