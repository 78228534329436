@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.contact-container {
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
  display: flex;
  flex-direction: column;
  background: linear-gradient(181deg, #000000 0%, #000000 45%, #323030 100%);
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
.contact-container .contact-header {
  margin: 0 5rem 5rem;
}
.contact-container .contact-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
}
.contact-container .contact-button button {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #F5F6FC;
  border: none;
  border-radius: 8px;
  padding: 18px 42px;
  cursor: pointer;
  background-color: transparent;
  color: #F5F6FC;
  border: 2px solid #F5F6FC;
}
.contact-container .contact-button button:hover {
  font-size: 14px;
  font-weight: 600;
  color: #F5F6FC;
  background-color: transparent;
  border: 2px solid #F5F6FC;
  border-radius: 8px;
  padding: 16px 40px;
  background-color: #F5F6FC;
  color: #000000;
  border: none;
}

@media (min-width: 768px) {
  .contact-container .contact-header {
    margin: 0 17rem 5rem;
    font-size: 60px;
  }
  .contact-container .contact-header h1 {
    text-align: center;
    font-size: 70px;
  }
  .contact-container .contact-button button {
    padding: 21px 42px;
    font-size: 18px;
  }
}/*# sourceMappingURL=contact.css.map */