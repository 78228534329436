@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';


.contact-page-container {
    @include font-styling;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-around;
    align-items: center;
    margin: 5rem 1rem;
    
    .contact-page-text{

        .contact-page-header {
            h1 {
                @include big-title-style;
            }
            
            p {
                @include pre-title-style;
            }
            
        }

        .contact-info-chat,
        .contact-info-call {
            display: none;
            margin: 2rem 0;

            h1 {
                @include big-title-style;
                font-size: 16px;

            }

            p {
                @include pre-title-style;
                text-transform: none;
                text-align: start;

            }
        }
    }

    .contact-form-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .contact-form {
            display: flex;
            flex-direction: column;

            .contact-booking {

                .calendly-inline-widget {
                    height: 69vh;

                }

            }
        }
    }

}


@media (min-width: 768px) {

    .contact-page-container {
        @include font-styling;
        flex-direction: row;
        align-items: flex-start;
        height: 90vh;

        .contact-page-text {

            .contact-page-header {
                p{
                    text-align: start;
                }
            }

            .contact-info {
                flex-direction: column;

                .contact-info-chat,
                .contact-info-call {
                    display: flex;
                    flex-direction: column;
                    margin: 2rem 0;

                }
            }
        }

    }
}