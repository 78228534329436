
@mixin button-style {
    padding: 1rem;
    margin: 1em 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.1em;
    color: #020303;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid black;
    cursor: pointer;
}


@mixin font-styling {
    font-family: 'Poppins', sans-serif, Courier New, Courier, monospace;
}

@mixin big-title-style {
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    text-align: left;
}

@mixin pre-title-style {
    font-size: 14px;
    text-align: center;
    color: #9DA2B2;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

@mixin image-styling {
    height: 180px;
    width: 300px;
    border-radius: 15px;
    object-fit: cover;
}

@mixin button-styling {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background-color: #F5F6FC;
    border: none;
    border-radius: 8px;
    padding: 18px 42px;
    cursor: pointer;
}

@mixin button-styling-hover {
    font-size: 14px;
    font-weight: 600;
    color: #F5F6FC;
    background-color: transparent;
    border: 2px solid #F5F6FC;
    border-radius: 8px;
    padding: 16px 40px;
}