@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';


.contact-container {
    @include font-styling;

    display: flex;
    flex-direction: column;
    background: linear-gradient(181deg, #000000 0%, #000000 45%, #323030 100%);
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    
    .contact-header {
        margin: 0 5rem 5rem;
        h1 {
            @include big-title-style;
        }
    }

    .contact-button{
        button {
            @include button-styling;
            background-color: transparent;
            color: #F5F6FC;
            border: 2px solid #F5F6FC;
        
        
        }
        
        button:hover {
            @include button-styling-hover;
            background-color: #F5F6FC;
            color: #000000;
            border: none;        

        }
    }
}

@media (min-width: 768px) {
    .contact-container {
        .contact-header {
            margin: 0 17rem 5rem;
            font-size: 60px;

            h1{
                text-align: center;
                font-size: 70px;
            }
        }

        .contact-button{
            button {
                padding: 21px 42px;
                font-size: 18px;
            }
        }
    }
    

}