@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.contact-page-container {
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
  align-items: center;
  margin: 5rem 1rem;
}
.contact-page-container .contact-page-text .contact-page-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
}
.contact-page-container .contact-page-text .contact-page-header p {
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.contact-page-container .contact-page-text .contact-info-chat,
.contact-page-container .contact-page-text .contact-info-call {
  display: none;
  margin: 2rem 0;
}
.contact-page-container .contact-page-text .contact-info-chat h1,
.contact-page-container .contact-page-text .contact-info-call h1 {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  font-size: 16px;
}
.contact-page-container .contact-page-text .contact-info-chat p,
.contact-page-container .contact-page-text .contact-info-call p {
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-transform: none;
  text-align: start;
}
.contact-page-container .contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact-page-container .contact-form-container .contact-form {
  display: flex;
  flex-direction: column;
}
.contact-page-container .contact-form-container .contact-form .contact-booking .calendly-inline-widget {
  height: 69vh;
}

@media (min-width: 768px) {
  .contact-page-container {
    font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
    flex-direction: row;
    align-items: flex-start;
    height: 90vh;
  }
  .contact-page-container .contact-page-text .contact-page-header p {
    text-align: start;
  }
  .contact-page-container .contact-page-text .contact-info {
    flex-direction: column;
  }
  .contact-page-container .contact-page-text .contact-info .contact-info-chat,
  .contact-page-container .contact-page-text .contact-info .contact-info-call {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }
}/*# sourceMappingURL=contactPage.css.map */