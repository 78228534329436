@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 100px;
  z-index: 10000;
}
.navbar-container .navbar-logo {
  display: none;
}
.navbar-container .navbar-menu {
  display: none;
}
.navbar-container .mobile-menu-container {
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.navbar-container .mobile-menu-container img {
  width: 40px;
  height: 90%;
}
.navbar-container .mobile-menu-container .mobile-menu-logo img {
  width: 100px;
}
.navbar-container .mobile-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: red;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.258);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px 0 0 5px;
}
.navbar-container .mobile-menu img {
  width: 30px;
  height: auto;
}
.navbar-container .mobile-menu p {
  margin: 5px 15px;
  padding: 0;
  transition: transform 0.3s ease;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.navbar-container .mobile-menu p a {
  display: inline-block;
  position: relative;
  color: #818380;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-transform: none;
  font-size: 24px;
  padding: 0.625rem;
  transition: background-color 0.3s ease;
  border-radius: 3.125rem;
}
.navbar-container .mobile-menu p a:hover {
  color: #000000;
  font-weight: 700;
}

@media (min-width: 1119px) {
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 180px;
    z-index: 1000;
  }
  .navbar-container .navbar-logo {
    display: contents;
  }
  .navbar-container .navbar-logo img {
    width: 200px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .navbar-container .navbar-menu {
    font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
    font-size: 14px;
    text-align: center;
    color: #9DA2B2;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    text-transform: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 85px;
    padding: 21px 50px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.101);
    border: 1px solid rgba(255, 255, 255, 0.258);
    border-radius: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-container .navbar-menu p {
    transition: transform 0.3s ease;
    white-space: nowrap;
  }
  .navbar-container .navbar-menu p a {
    display: inline-block;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    padding: 0.625rem;
    transition: background-color 0.3s ease;
    border-radius: 3.125rem;
  }
  .navbar-container .navbar-menu p.selected {
    padding: 5px 15px;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.101);
    border: 1px solid rgba(255, 255, 255, 0.258);
    border-radius: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .navbar-container .mobile-menu-container {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */