@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.gallery-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 9999;
    margin: 0 1rem 5rem 1rem;


    .gallery-page-header {
        margin: 0 0 5rem 0;

        h1 {
            @include big-title-style;
        }

        p {
            @include pre-title-style;
        }
    }

    .image-preview {
        // margin: 20px;

        img {
            @include image-styling;
            height: 400px;
        }
    }

    .image-list {
        display: flex;
        margin: 10px;
        width: 90vw;
        overflow-x: scroll;

        .image-button {
            border: none;
            background: none;
            cursor: pointer;
            padding: 5px;

            img {
                width: 80px;
                height: 60px;
                border-radius: 4px;
                margin-right: 5px;
                object-fit: cover;
                transition: transform 0.2s;
                filter: blur(2px);
            }

            &:hover img {
                transform: scale(1.1);
                filter: blur(0px);
            }
        }
    }
}

@media (min-width: 768px) {
    .gallery-page-container {
        .image-preview {
            img {
                max-width: 600px;
            }
        }
    }

    .gallery-page-container {
        .image-list {
            .image-button {
                img {
                    width: 120px;
                    height: 90px;
                }
            }
        }
    }
}