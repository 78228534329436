* {
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

body {
  /* background-color: var(--color-bg); */
  /* color: #ffffff; */
  min-height: 100vh;
  font-size: 16px;
}

body::before, body::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100px;
  pointer-events: none;
}

body::before {
  top:0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
}

body::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}