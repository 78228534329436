@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

footer{
    margin: 0;
    overflow-x: hidden;
}
.footer {
    background: linear-gradient(0deg, #000000 0%, #000000 45%, #323030 100%);
    padding: 30px 0px;
    font-family: 'Play', sans-serif;
    text-align: center;
    
    .footer-row {
        width: 100%;
        margin: 1% 0%;
        padding: 0.6% 0%;
        color: gray;
        font-size: 0.8em;

        a {
            text-decoration: none;
            color: gray;
            transition: 0.5s;
        }
        
        a:hover {
            color: #fff;
        }

        ul {
            width: 100%;
            
            li {
                display: inline-block;
                margin: 0px 30px;
            }
        }
        
        a i {
            font-size: 2em;
            margin: 0% 1%;
        }
    }
}


@media (max-width:720px) {
    .footer {
        text-align: center;
        padding: 5%;

        .footer-row {
            margin-top: 20px;
            ul {
                li {
                    display: unset;
                    margin: 10px 10px;
                    text-align: center;
                }
            }
            
            a i {
                margin: 0% 3%;
            }
        }
    }

}