@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.gallery-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 5rem 1rem;
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
}
.gallery-container .gallery-title {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  font-size: 24px;
}
.gallery-container .gallery-pic-bg {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 15px;
  padding: 0px 0;
  margin: 30px 0;
}
.gallery-container .gallery-pic-bg .gallery-pic-container img {
  height: 180px;
  width: 300px;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery-container .gallery-pic-bg .gallery-pic-container p {
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  position: relative;
  bottom: 21px;
}
.gallery-container .gallery-button button {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #F5F6FC;
  border: none;
  border-radius: 8px;
  padding: 18px 42px;
  cursor: pointer;
}
.gallery-container .gallery-button button:hover {
  font-size: 14px;
  font-weight: 600;
  color: #F5F6FC;
  background-color: transparent;
  border: 2px solid #F5F6FC;
  border-radius: 8px;
  padding: 16px 40px;
}

@media (min-width: 768px) {
  .gallery-container {
    margin: 5rem 8rem;
  }
  .gallery-container .gallery-title {
    font-size: 60px;
  }
  .gallery-container .gallery-pic-bg {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 55px;
  }
  .gallery-container .gallery-pic-bg .gallery-pic-container img {
    height: 300px;
    width: 500px;
  }
  .gallery-container .gallery-pic-bg .gallery-pic-container img:hover {
    cursor: pointer;
    height: 310px;
    width: 580px;
  }
  .gallery-container .gallery-button {
    padding: 5rem 0;
  }
  .gallery-container .gallery-button button {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background-color: #F5F6FC;
    border: none;
    border-radius: 8px;
    padding: 18px 42px;
    cursor: pointer;
    padding: 25px 42px;
    font-size: 18px;
  }
  .gallery-container .gallery-button button:hover {
    font-size: 14px;
    font-weight: 600;
    color: #F5F6FC;
    background-color: transparent;
    border: 2px solid #F5F6FC;
    border-radius: 8px;
    padding: 16px 40px;
    padding: 28px;
  }
}/*# sourceMappingURL=gallery.css.map */