@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.gallery-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 9999;
  margin: 0 1rem 5rem 1rem;
}
.gallery-page-container .gallery-page-header {
  margin: 0 0 5rem 0;
}
.gallery-page-container .gallery-page-header h1 {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
}
.gallery-page-container .gallery-page-header p {
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.gallery-page-container .image-preview img {
  height: 180px;
  width: 300px;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
  height: 400px;
}
.gallery-page-container .image-list {
  display: flex;
  margin: 10px;
  width: 90vw;
  overflow-x: scroll;
}
.gallery-page-container .image-list .image-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 5px;
}
.gallery-page-container .image-list .image-button img {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  margin-right: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.2s;
  filter: blur(2px);
}
.gallery-page-container .image-list .image-button:hover img {
  transform: scale(1.1);
  filter: blur(0px);
}

@media (min-width: 768px) {
  .gallery-page-container .image-preview img {
    max-width: 600px;
  }
  .gallery-page-container .image-list .image-button img {
    width: 120px;
    height: 90px;
  }
}/*# sourceMappingURL=galleryPage.css.map */