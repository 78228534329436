@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';


.services-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0rem 1rem;
    @include font-styling;
    
    .service-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem 0rem;

        .services-smaller-title{
            @include pre-title-style;
        }
        
        .services-title{
            @include big-title-style;
            padding: 0.5rem 0;
        }
    }

    .services-content-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        
        .services-left-content{
            margin: 0 0rem 5rem 0rem;
            text-align: left;
            font-size: 16px;
            font-weight: 300;
        }

        .services-right-content{
            img{
                @include image-styling;
            }
        }
    }
}



@media (min-width: 768px) {
    .services-container{
        // min-height: 100vh;
        margin: 0rem 8rem;
        
        .service-header{
            align-items: flex-start;
            margin: unset;
    
            .services-smaller-title{
                font-size: 16px;
                text-align: left;
            }
            
            .services-title{
                font-size: 70px;
            }
        }
    
        .services-content-container{
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            
            .services-left-content{
                margin: unset;
                font-size: 24px;
                font-weight: 400;
            }
            
            .services-right-content{
                margin: 0  0 0 25rem;
                img{
                    height: 550px;
                    width: 500px;
                }
            }
        }
    }

}