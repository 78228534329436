@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.hero-container {
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  z-index: 3;
}
.hero-container .hero-video video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero-container .hero-front-text {
  position: relative;
  margin-top: 5vh;
}
.hero-container .hero-front-text .hero-header {
  font-size: 30px;
  text-align: center;
}
.hero-container .hero-front-text .hero-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}
.hero-container .hero-front-text .hero-button .play-button {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 15px;
  background-color: #000000;
  border: none;
  border-radius: 50%;
}
.hero-container .hero-front-text .hero-button .play-button img {
  width: 10px;
}
.hero-container .hero-front-text .hero-button p {
  font-size: 16px;
  font-weight: 800;
}
.hero-container .hero-front-text .hero-button :hover {
  cursor: pointer;
  color: #dfdddd;
}
.hero-container .hero-front-text .hero-text-stack {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 30px;
  -moz-column-gap: 0px;
       column-gap: 0px;
  margin-top: 20px;
}
.hero-container .hero-front-text .hero-text-stack .text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.hero-container .hero-front-text .hero-text-stack .text-wrapper .text-title p {
  font-size: 16px;
  font-weight: 800;
}
.hero-container .hero-front-text .hero-text-stack .text-wrapper .text-desc p {
  font-size: 15px;
  font-weight: 300;
}

@media (min-width: 768px) {
  .hero-container {
    height: 500px;
    width: unset;
  }
  .hero-container .hero-front-text {
    margin: 99px 250px 99px 250px;
  }
  .hero-container .hero-front-text .hero-header {
    font-size: 50px;
    text-align: start;
  }
  .hero-container .hero-front-text .hero-button {
    justify-content: flex-start;
  }
  .hero-container .hero-front-text .hero-button .play-button {
    padding: 30px;
  }
  .hero-container .hero-front-text .hero-button .play-button img {
    width: 20px;
  }
  .hero-container .hero-front-text .hero-button p {
    font-size: 24px;
  }
  .hero-container .hero-text-stack {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: flex-start;
    align-items: flex-start;
    width: 30%;
    align-items: flex-start;
  }
  .hero-container .hero-text-stack .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}/*# sourceMappingURL=hero.css.map */