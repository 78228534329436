@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 100px;
    z-index: 10000;

    .navbar-logo {
        display:none;
    }

    .navbar-menu {
        display: none;

    }

    .mobile-menu-container {
        @include font-styling;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        img {
            width: 40px;
            height: 90%;
        }

        .mobile-menu-logo {

            img {
                width: 100px;
            }
        }
    }

    .mobile-menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: 60%;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(10px);
        background-color: red;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgba(255, 255, 255, 0.258);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        align-items: flex-end;
        justify-content: flex-start;
        padding: 20px 0 0 5px;

        img {
            width: 30px; 
            height: auto;
          }

        p {
            margin: 5px 15px;
            padding: 0;
            transition: transform 0.3s ease;
            white-space: nowrap;

            width: 100%;
            display: flex;
            justify-content: flex-end;

            a {
                display: inline-block;
                position: relative;
                color: #818380;
                text-decoration: none;
                @include pre-title-style;
                text-transform: none;
                font-size: 24px;
                padding: 0.625rem;
                transition: background-color 0.3s ease;
                border-radius: 3.125rem;
            }

            a:hover{
                color: #000000;
                font-weight: 700;

            }
        }
    }

}




@media (min-width: 1119px) {

    .navbar-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: relative;
        height: 180px;
        z-index: 1000;

        .navbar-logo {
            display:contents;
            img {
                width: 200px;
                object-fit: contain;
            }
        }

        .navbar-menu {
            @include font-styling;
            @include pre-title-style;
            font-weight: 700;
            font-size: 16px;
            text-transform: none;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 85px;
            padding: 21px 50px;
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.101);
            border: 1px solid rgba(255, 255, 255, 0.258);
            border-radius: 60px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            p {
                transition: transform 0.3s ease;
                white-space: nowrap;

                a {
                    display: inline-block;
                    position: relative;
                    color: #ffffff;
                    text-decoration: none;
                    padding: 0.625rem;
                    transition: background-color 0.3s ease;
                    border-radius: 3.125rem;
                }

                &.selected {
                    padding: 5px 15px;
                    backdrop-filter: blur(10px);
                    background-color: rgba(255, 255, 255, 0.101);
                    border: 1px solid rgba(255, 255, 255, 0.258);
                    border-radius: 60px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }
            }

        }

        .mobile-menu-container {
            display: none;
        }
    }
}