@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.services-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0rem 1rem;
  font-family: "Poppins", sans-serif, Courier New, Courier, monospace;
}
.services-container .service-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem 0rem;
}
.services-container .service-header .services-smaller-title {
  font-size: 14px;
  text-align: center;
  color: #9DA2B2;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.services-container .service-header .services-title {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  padding: 0.5rem 0;
}
.services-container .services-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.services-container .services-content-container .services-left-content {
  margin: 0 0rem 5rem 0rem;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
}
.services-container .services-content-container .services-right-content img {
  height: 180px;
  width: 300px;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (min-width: 768px) {
  .services-container {
    margin: 0rem 8rem;
  }
  .services-container .service-header {
    align-items: flex-start;
    margin: unset;
  }
  .services-container .service-header .services-smaller-title {
    font-size: 16px;
    text-align: left;
  }
  .services-container .service-header .services-title {
    font-size: 70px;
  }
  .services-container .services-content-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .services-container .services-content-container .services-left-content {
    margin: unset;
    font-size: 24px;
    font-weight: 400;
  }
  .services-container .services-content-container .services-right-content {
    margin: 0 0 0 25rem;
  }
  .services-container .services-content-container .services-right-content img {
    height: 550px;
    width: 500px;
  }
}/*# sourceMappingURL=services.css.map */