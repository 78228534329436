@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');

@import '../../styles/globals';

.gallery-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 5rem 1rem;
    @include font-styling;

    .gallery-title {
        @include big-title-style;
        font-size: 24px;
    }

    .gallery-pic-bg {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 15px;
        padding: 0px 0;
        margin: 30px 0;

        .gallery-pic-container {
            img{
                @include image-styling;
            }

            p{
                @include pre-title-style;
                color: #ffff;
                font-weight: 600;
                position: relative;
                bottom: 21px;
            }
        }

    }


    .gallery-button{
        button{
            @include button-styling;
        }

        button:hover {
            @include button-styling-hover;
        }
    }

}


@media (min-width: 768px) {
    .gallery-container{
        margin: 5rem 8rem;

        .gallery-title {
            font-size: 60px;


        }
    
        .gallery-pic-bg {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-row-gap: 55px;

            .gallery-pic-container {
                img{
                    height: 300px;
                    width: 500px;
                }

                img:hover {
                    cursor: pointer;
                    height: 310px;
                    width: 580px;
                }
            }
    
        }

        .gallery-button{
            padding: 5rem 0;
            button{
                @include button-styling;
                padding: 25px 42px;
                font-size: 18px;
            }
    
            button:hover {
                @include button-styling-hover;
                padding: 28px;
            }
        }
    
    }

}
